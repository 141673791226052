import request from '@/utils/request'

// 关于我们详情
export const aboutUsAPI = (params) => {
  return request({
    url: '/ourInfo',
    method: 'GET',
    params
  })
}

// 保存详情
export const saveDetailsAPI = (data) => {
  return request({
    url: '/ourSave',
    method: 'POST',
    data
  })
}
